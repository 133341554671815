import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import LZString from 'lz-string';

import debounce from 'lodash/debounce';
import flowRight from 'lodash/flowRight';

// material UI
import Collapse from '@material-ui/core/Collapse';
import Fade from '@material-ui/core/Fade';
import Fab from '@material-ui/core/Fab';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import AddIcon from '@material-ui/icons/Add';
import Popover from '@material-ui/core/Popover';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Icon from '@mdi/react';
import { mdiDomain as mdiCompanyIcon } from '@mdi/js';
import withUser from '../utils/withUser';
// components
import MainSearchField from '../generic_components/MainSearchField';
import CompanyResults from '../companyArchive/CompanyResult';
import CompanyDetailSearch from '../companyArchive/CompanyDetailSearch';
import ConfirmNewProjectPopUp from '../companyArchive/ConfirmNewProjectPopUp';
import SingleCompanyResult from '../companyArchive/SingleCompanyResult';
import CompanyCreateUpdatePopUp from '../companyArchive/CompanyCreateUpdatePopUp';
import CounterPartCUPopUp from '../companyArchive/CounterPartCUPopUp';
import PastContactCreateUpdatePopUp from '../companyArchive/PastContactCreateUpdatePopUp';
import AddReminderPopUp from '../companyArchive/AddReminderPopUp';
import CompanyImportModal from '../companyArchive/CompanyImportModal';
import OfferCreateUpdatePopUp from '../offers/components/OfferCreateUpdatePopUp';
// basics
import * as UTILS from '../utils/utilFunctions';
import TEXT from '../text';
import * as CONSTANTS from '../constants';
import onNextPreviousItemClick from '../utils/forwardBackwardsUtils';
import * as companyAPI from '../companyArchive/api/companyArchiveAPI';
import isPermissionSet from '../utils/isPermissionSet';
import { post } from '../utils/apiUtils';

function prepareEmployeesKeyValuePairs(employees) {
  const mappedDropDownEntries = [];
  if (employees && employees.length > 0) {
    if (CONSTANTS.isLienert) {
      employees.forEach(employee => {
        if (employee.lastname && employee.isAdvisor) {
          const value = employee.id;
          const label = `${employee.firstname || ''} ${employee.lastname}`;
          const mappedEmployee = { value, label };
          mappedDropDownEntries.push(mappedEmployee);
        }
      });
    } else {
      employees.forEach(employee => {
        if (employee.lastname) {
          const value = employee.id;
          const label = `${employee.firstname || ''} ${employee.lastname}`;
          const mappedEmployee = { value, label };
          mappedDropDownEntries.push(mappedEmployee);
        }
      });
    }
  }
  return mappedDropDownEntries;
}
class CompanyArchive extends PureComponent {
  constructor(props) {
    super(props);
    const companyIdFromParms = UTILS.getURLParam(this.props.history, 'id');
    let currentTab = UTILS.getURLParam(this.props.history, 't');
    currentTab = currentTab ? parseInt(currentTab, 10) : 0;

    sessionStorage.removeItem('displayedCompanyIndexes');

    this.state = {
      preSelectedCounterPartId: UTILS.getURLParam(this.props.history, 'cpid'),
      isPopoverOpen: false, // menu above add button
      // popUps
      isCompanyCreateUpdatePopUpOpen: false,
      isFilePickerModalOpen: false,
      isCounterPartCUPopUpOpen: false,
      isPastContactCreateUpdatePopUpOpen: false,
      isPastContactDefaultPhoneCall: false,
      isAddReminderPopUpOpen: false,
      currentTab,
      selectedCompany: {
        id: '',
        name: '',
        companyType: '',
        address: {
          street: '',
          postalCode: '',
          city: '',
          country: ''
        },
        contactInfo: {
          phoneNumber: '',
          faxNumber: '',
          url: '',
          urlCareerPage: ''
        },
        industrySector: '',
        companySize: '',
        status: {},
        comment: ''
      },
      counterParts: [],
      pastContacts: [],
      companyList: [],
      notifications: [],
      projects: [],
      showResultList: false,
      showCompanyDetails: false,
      showCompanyDetailsColumn: true,
      searchFieldValidationError: false,
      showCreateProjectModular: false,
      showCreateOfferModular: false,
      projectNumber: '',
      isDetailSearchDrawerOpen: false,
      partners: [],
      employees: [],
      searchFieldAutoFocus: !(companyIdFromParms && companyIdFromParms.length > 0),
      maxAmountOfSearchResults: 100,
      createProjectButtonIsDisabled: false,
      offerData: { offerData: { offerList: [] }, offerCount: 0 },
      offerTablePaging: { page: 0 },
      selectedOffer: {}
    };
    this.mainSearchFieldChange = flowRight(
      debounce(this.mainSearchFieldChange, 200),
      e => e.persist() || e
    );
    this.errorHandling = UTILS.errorHandling.bind(this);
    this.handleAddMenuIconButtonClick = this.handleAddMenuIconButtonClick.bind(this);
  }

  componentDidMount() {
    document.title = `Unternehmen - ${UTILS.getTitle()}`;
    const searchQueryFromParams = UTILS.getURLParam(this.props.history, 'sq');
    const companyIdFromParms = UTILS.getURLParam(this.props.history, 'id');
    const lastSearchTypeFromParms = UTILS.getURLParam(this.props.history, 'ls');
    const marklistIdFromParams = UTILS.getURLParam(this.props.history, 'mid');
    const maxAmountOfSearchResults = UTILS.getURLParam(this.props.history, 'ma');
    let detailSearchQueryFromParams = UTILS.getURLParam(this.props.history, 'dq');
    if (detailSearchQueryFromParams) detailSearchQueryFromParams = LZString.decompressFromEncodedURIComponent(detailSearchQueryFromParams);

    try {
      detailSearchQueryFromParams = JSON.parse(detailSearchQueryFromParams);
    } catch (e) {
      detailSearchQueryFromParams = undefined;
    }
    if (companyIdFromParms && companyIdFromParms.length > 0) {
      this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: true });
      this.getCompanyAPICall(companyIdFromParms);
    }
    if (searchQueryFromParams && lastSearchTypeFromParms && lastSearchTypeFromParms === 'query') {
      this.getCompanyListBySearchTerm(searchQueryFromParams, !(companyIdFromParms && companyIdFromParms.length > 0));
    }
    if (detailSearchQueryFromParams && lastSearchTypeFromParms && lastSearchTypeFromParms === 'detail') {
      this.getCompanyListByDetailSearchAPICall(
        detailSearchQueryFromParams,
        maxAmountOfSearchResults,
        !(companyIdFromParms && companyIdFromParms.length > 0)
      );
    }
    if (lastSearchTypeFromParms === 'marklist' && marklistIdFromParams) {
      this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: true });
      this.getCompanyListByMarkListId(marklistIdFromParams);
    }

    this.getPartnersOfCompanyAPICall();
    this.getEmployeeListByPartnerAPICall();
  }

  getCompanyListByDetailSearchAPICall = async (detailSearchFieldList, maxAmountOfSearchResults, showResultList) => {
    const filteredDetailSearchFieldList = detailSearchFieldList.filter(
      item =>
        item.dropDownValue !== 'none' &&
        (item.fieldValue !== undefined && item.fieldValue.length !== 0) &&
        Object.values(item.fieldValue).every(itemValue => itemValue !== '')
    );
    this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: true });
    try {
      const response = await companyAPI.getCompanyListByDetailSearch(filteredDetailSearchFieldList, maxAmountOfSearchResults);

      this.setState({
        showResultList,
        companyList: response,
        maxAmountOfSearchResults: Number.parseInt(maxAmountOfSearchResults, 10) || 100
      });
    } catch (e) {
      this.errorHandling(e);
    } finally {
      this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });
    }
  };

  liftUpSelectedCompany = updatedEntry => {
    this.setState(prevState => ({
      selectedCompany: {
        ...prevState.selectedCompany,
        ...updatedEntry
      }
    }));
  };

  liftUpUpdateCounterPartDetails = (id, updatedEntry) => {
    this.setState(prevState => {
      const counterParts = prevState.counterParts;
      const listPosition = counterParts.findIndex(element => element.id === id);
      counterParts[listPosition] = updatedEntry;
      return {
        counterParts
      };
    });
  };

  liftUpUpdatePastContactDetails = (id, updatedEntry) => {
    this.setState(prevState => {
      const pastContacts = prevState.pastContacts;
      const listPosition = pastContacts.findIndex(element => element.id === id);
      pastContacts[listPosition].contactType = updatedEntry.contactType;
      pastContacts[listPosition].contactPerson = updatedEntry.contactPerson;
      pastContacts[listPosition].companyStatus = updatedEntry.companyStatus;
      pastContacts[listPosition].comment = updatedEntry.comment;
      return {
        pastContacts
      };
    });
  };

  removeCounterPart = counterPartId => {
    this.setState(prevState => {
      const counterParts = prevState.counterParts.filter(entry => entry.id !== counterPartId);
      return { counterParts };
    });
  };

  removePastContact = pastContactId => {
    this.setState(prevState => {
      const pastContacts = prevState.pastContacts.filter(entry => entry.id !== pastContactId);
      return { pastContacts };
    });
  };

  // WARNING not working for key: showResultList value: true (for example)
  liftUpStateToCompanyArchive = (key, value) => {
    this.setState(prevState => ({ [key]: { ...prevState[key], ...value } }));
  };

  liftUpStateObjectToCompanyArchive = stateObject => {
    this.setState(stateObject);
  };

  async getEmployeeListByPartnerAPICall() {
    try {
      const response = await companyAPI.getEmployeeListByPartner();
      this.setState({
        employees: prepareEmployeesKeyValuePairs(response)
      });
    } catch (error) {
      this.errorHandling(error);
    }
  }

  async getPartnersOfCompanyAPICall() {
    try {
      const response = await companyAPI.getPartnersOfCompany(this.props.user.role === 'admin');

      this.setState({
        partners: response.partners
      });
    } catch (error) {
      this.errorHandling(error);
    }
  }

  handleExport(exportType = 'all') {
    const url = exportType === 'christmas' ? CONSTANTS.exportChristmasCSVURL : CONSTANTS.exportCompanyCSVURL;
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    const requestBody = {};

    const xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = () => {
      let a;
      if (xhttp.readyState === 4 && xhttp.status === 200) {
        // Trick for making downloadable link
        a = document.createElement('a');
        a.href = window.URL.createObjectURL(xhttp.response);
        // Give filename you wish to download
        a.download = exportType === 'christmas' ? 'Weihnachtsversand-Export.csv' : 'companies_export.csv';
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        this.setState({
          isISheetDownloadButtonActive: ''
        });
      }
    };
    // Post data to URL which handles post request
    xhttp.open('POST', url);
    xhttp.setRequestHeader('Content-Type', 'text/csv');
    xhttp.setRequestHeader('x-auth', tokenFromLocalStorage);
    // You should set responseType as blob for binary responses
    xhttp.responseType = 'blob';
    xhttp.send(JSON.stringify(requestBody));
  }

  async getCompanyListByMarkListId(companyMarkListId) {
    try {
      const response = await companyAPI.getCompanyListByIds(companyMarkListId);
      if (response.length === 1) {
        this.setState({
          companyList: response
        });
        this.getCompanyAPICall(response[0].id);
      } else this.setState({ showResultList: true, companyList: response });
    } catch (error) {
      this.errorHandling(error);
    } finally {
      this.props.liftUpStateToApp({
        showLoadingIndicatorGlobal: false
      });
    }
  }

  mainSearchFieldChange = e => {
    UTILS.setURLParam(this.props.history, 'sq', e.target.value);
    UTILS.setURLParam(this.props.history, 'ls', 'query');
    this.getCompanyListBySearchTerm(e.target.value, true);
  };

  async getCompanyListBySearchTerm(searchTerm, showResultListAfterLoad = true) {
    if (!UTILS.isSearchtermValid(searchTerm)) {
      this.setState({ searchFieldValidationError: true });
      return;
    }
    if (this.state.searchFieldValidationError) this.setState({ searchFieldValidationError: false });

    try {
      const response = await companyAPI.getCompanyListBySearchTerm(searchTerm);
      this.setState({
        showResultList: showResultListAfterLoad,
        companyList: response
      });
    } catch (error) {
      this.errorHandling(error);
    }
  }

  getCounterPartReminderListByCompanyAPICall = async companyId => {
    try {
      const response = await companyAPI.getCounterPartReminderListByCompany(companyId);
      this.setState({
        notifications: response
      });
    } catch (error) {
      this.errorHandling(error);
    }
  };

  getProjectByCompanyIdAPICall = async companyId => {
    try {
      const response = await companyAPI.getProjectByCompanyId(companyId);
      this.setState({
        projects: response.projects
      });
    } catch (error) {
      this.errorHandling(error);
    }
  };

  getCompanyAPICall = async companyId => {
    UTILS.setURLParam(this.props.history, 'id', companyId);
    try {
      const response = await companyAPI.getCompany(companyId);
      this.getCounterPartReminderListByCompanyAPICall(companyId);
      this.getProjectByCompanyIdAPICall(companyId);

      this.setState({
        showCompanyDetails: true,
        showResultList: false,
        selectedCompany: response.company,
        counterParts: response.counterParts,
        pastContacts: response.pastContacts,
        isLoading: false
      });
    } catch (error) {
      this.errorHandling(error);
    } finally {
      this.props.liftUpStateToApp({
        companyMarkListId: undefined,
        showLoadingIndicatorGlobal: false
      });
    }
  };

  async createProjectAPICall(companyId, projectNumber) {
    try {
      const response = await companyAPI.createProject(companyId, projectNumber);
      this.props.history.push({
        pathname: '/projects',
        search: `?id=${response.id}`
      });
    } catch (error) {
      this.errorHandling(error, null, null, [400]);
      if (error.status === 400) {
        this.props.handleSnackbarOpen('Die Auftragsnummer ist bereits vergeben!');
      }
    }
  }

  refreshCompany = companyId => {
    this.getCompanyAPICall(companyId);
  };

  setLoading = counterPartId => {
    this.setState({
      isLoading: counterPartId
    });
  };

  handleCompanySelected = selectedRow => {
    this.setState({
      showResultList: false,
      showCompanyDetails: true
    });
    this.getCompanyAPICall(selectedRow[0]);
  };

  handleCloseCounterPartCUPopup = reload => {
    this.setState({
      isCounterPartCUPopUpOpen: false
    });
    if (reload) {
      this.getCompanyAPICall(this.state.selectedCompany.id);
    }
  };

  handleCloseCompanyCreateUpdatePopUp = (id, reload) => {
    this.setState({ isCompanyCreateUpdatePopUpOpen: false });
    if (reload) {
      this.getCompanyAPICall(id);
    }
  };

  handleClosePastContactCreateUpdatePopUp = reload => {
    this.setState({
      isPastContactCreateUpdatePopUpOpen: false
    });
    if (reload) {
      this.getCompanyAPICall(this.state.selectedCompany.id);
    }
  };

  openContactHistoryForPhone = () => {
    this.setState({
      isPastContactCreateUpdatePopUpOpen: true,
      isPastContactDefaultPhoneCall: true
    });
  };

  handleCloseAddReminderPopup = reload => {
    this.setState({
      isAddReminderPopUpOpen: false
    });
    if (reload) {
      this.getCompanyAPICall(this.state.selectedCompany.id);
    }
  };

  handleCollapseIconButtonClick = () => {
    this.setState(prevState => ({
      showCompanyDetailsColumn: !prevState.showCompanyDetailsColumn
    }));
  };

  handleCreateProjectClick = (companyId, projectNumber) => {
    this.createProjectAPICall(companyId, projectNumber);
    this.setState({
      createProjectButtonIsDisabled: true
    });
  };

  handleAddMenuIconButtonClick() {
    this.setState({ isPopoverOpen: true });
  }

  handleLoadOffers = async (companyId, pageNumber, pageSize) => {
    this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: true });
    const data = await post(CONSTANTS.getCompanyOfferList, { companyId, pageNumber, pageSize });

    if (typeof data.totalCount === 'undefined' || !data.totalCount > 0) {
      this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });
      this.setState({ offerData: { offerList: [] }, offerCount: 0 });
    } else {
      this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });
      this.setState({ offerData: data });
    }
  };

  handleChangeOfferTablePageNumber = pageNumber => {
    this.setState({ offerTablePaging: { page: pageNumber } });
  };

  showOffersCreateUpdatePopUp = show => {
    this.setState({ showCreateOfferModular: show });
  };

  setSelectedOffer = offer => {
    if (typeof offer !== 'object') {
      this.setState({ selectedOffer: {} });
      return;
    }
    this.setState({ selectedOffer: offer });
  };

  onProjectNumberChange = e => {
    const value = e.target.value;
    this.setState({
      projectNumber: value
    });
  };

  render() {
    const collapseIconStyle = {
      position: 'absolute',
      bottom: '30px',
      left: 'calc(-30px + 16%)',
      zIndex: 1000
    };
    if (this.state.showCompanyDetailsColumn === false) collapseIconStyle.left = '20px';

    return (
      <div className="CompanyArchive  h-100-minusAppBar conatiner-fluid">
        <div
          className={
            this.state.showResultList || this.state.showCompanyDetails
              ? ''
              : 'col-4 mx-auto ml-auto d-flex flex-column justify-content-center align-items-center h-100vh'
          }
        >
          {!(this.state.showResultList || this.state.showCompanyDetails) && (
            <Icon
              path={mdiCompanyIcon}
              size={6}
              color="#ccc"
              style={{
                marginBottom: '16px',
                marginTop: '-64px'
              }}
            />
          )}

          <Fade direction="up" in style={{ width: '100%' }}>
            <div
              className={
                this.state.showResultList || this.state.showCompanyDetails ? 'd-flex justify-content-center align-items-center' : undefined
              }
            >
              {(this.state.showResultList || this.state.showCompanyDetails) && (
                <IconButton
                  aria-label="back"
                  color="primary"
                  disabled={!this.state.companyList || this.state.companyList.length === 0 || !this.state.selectedCompany.id}
                  onClick={() =>
                    onNextPreviousItemClick(
                      false,
                      this.state.selectedCompany.id,
                      this.state.companyList,
                      'displayedCompanyIndexes',
                      this.getCompanyAPICall
                    )
                  }
                >
                  <ArrowBackIosIcon />
                </IconButton>
              )}
              <div style={this.state.showResultList || this.state.showCompanyDetails ? { width: '33%' } : {}}>
                <MainSearchField
                  placeholder="Bitte suchen Sie ein Unternehmen"
                  autoFocus={this.state.searchFieldAutoFocus}
                  onChange={this.mainSearchFieldChange}
                  defaultValue={UTILS.getURLParam(this.props.history, 'sq')}
                  onFocus={() => {
                    if (this.state.companyList.length) this.setState({ showResultList: true });
                  }}
                  error={this.state.searchFieldValidationError}
                  helperText={this.state.searchFieldValidationError && TEXT.companyArchive.searchFieldErrorMessage}
                  onExtendedSearchClick={() =>
                    this.setState({
                      isDetailSearchDrawerOpen: true
                    })
                  }
                />
              </div>
              {(this.state.showResultList || this.state.showCompanyDetails) && (
                <IconButton
                  aria-label="Forward"
                  color="primary"
                  disabled={!this.state.companyList || this.state.companyList.length === 0 || !this.state.selectedCompany.id}
                  onClick={() =>
                    onNextPreviousItemClick(
                      true,
                      this.state.selectedCompany.id,
                      this.state.companyList,
                      'displayedCompanyIndexes',
                      this.getCompanyAPICall
                    )
                  }
                >
                  <ArrowForwardIosIcon />
                </IconButton>
              )}
            </div>
          </Fade>
        </div>
        <Collapse
          direction="down"
          mountOnEnter
          in={this.state.showResultList}
          {...(this.state.showCompanyDetails ? { timeout: 800 } : { timeout: 800 })}
        >
          <div className="row">
            <div className="col-12">
              <CompanyResults
                // liftUpStateToApp={this.liftUpStateToApp}
                companyList={this.state.companyList}
                maxAmountOfSearchResults={this.state.maxAmountOfSearchResults}
                handleCompanySelected={this.handleCompanySelected}
                handleSnackbarOpen={this.props.handleSnackbarOpen}
              />
            </div>
          </div>
        </Collapse>
        <Fade
          direction="up"
          in={this.state.showCompanyDetails}
          mountOnEnter
          unmountOnExit
          {...(this.state.showCompanyDetails ? { timeout: 1000 } : {})}
        >
          <SingleCompanyResult
            handleSnackbarOpen={this.props.handleSnackbarOpen}
            errorHandling={this.errorHandling}
            selectedCompany={this.state.selectedCompany}
            notifications={this.state.notifications}
            counterParts={this.state.counterParts}
            pastContacts={this.state.pastContacts}
            projects={this.state.projects}
            refreshCompany={this.refreshCompany}
            setLoading={this.setLoading}
            isLoading={this.state.isLoading}
            showCreateOfferModular={this.state.showCreateOfferModular}
            handShowOffersCreateUpdatePopUp={this.showOffersCreateUpdatePopUp}
            showCompanyDetailsColumn={this.state.showCompanyDetailsColumn}
            preSelectedCounterPartId={this.state.preSelectedCounterPartId}
            liftUpStateToApp={this.props.liftUpStateToApp}
            liftUpUpdateCounterPartDetails={this.liftUpUpdateCounterPartDetails}
            liftUpUpdatePastContactDetails={this.liftUpUpdatePastContactDetails}
            getCounterPartReminderListByCompanyAPICall={this.getCounterPartReminderListByCompanyAPICall}
            liftUpStateToCompanyArchive={this.liftUpStateToCompanyArchive}
            handleClosePastContactCreateUpdatePopUp={this.handleClosePastContactCreateUpdatePopUp}
            partners={this.state.partners.map(partner => {
              if (partner.status === 'deleted') {
                return {
                  ...partner,
                  partnerName: `${partner.partnerName} (ausgeschieden)`
                };
              }
              return partner;
            })}
            employees={this.state.employees}
            openContactHistoryForPhone={this.openContactHistoryForPhone}
            liftUpStateObjectToCompanyArchive={this.liftUpStateObjectToCompanyArchive}
            currentTab={this.state.currentTab}
            removeCounterPart={this.removeCounterPart}
            removePastContact={this.removePastContact}
            liftUpSelectedCompany={this.liftUpSelectedCompany}
            loadOffers={this.handleLoadOffers}
            offerData={this.state.offerData}
            handleChangeTablePageNumber={this.handleChangeOfferTablePageNumber}
            offerTablePaging={this.state.offerTablePaging}
            setSelectedOffer={this.setSelectedOffer}
            selectedOffer={this.state.selectedOffer}
          />
        </Fade>
        <div style={{ height: '100px' }} className="spacer" />
        <CompanyCreateUpdatePopUp
          open={this.state.isCompanyCreateUpdatePopUpOpen}
          handleClose={this.handleCloseCompanyCreateUpdatePopUp}
          liftUpStateToCompanyArchive={this.liftUpStateToCompanyArchive}
          handleSnackbarOpen={this.props.handleSnackbarOpen}
          employees={this.state.employees}
        />
        <CompanyImportModal
          open={this.state.isFilePickerModalOpen}
          handleClose={() => this.setState({ isFilePickerModalOpen: false })}
          liftUpStateToCompanyArchive={this.liftUpStateToCompanyArchive}
          handleSnackbarOpen={this.props.handleSnackbarOpen}
        />
        <CounterPartCUPopUp
          key={`${this.state.selectedCompany.id}-${this.state.counterParts.length}`}
          open={this.state.isCounterPartCUPopUpOpen}
          handleClose={this.handleCloseCounterPartCUPopup}
          handleSnackbarOpen={this.props.handleSnackbarOpen}
          companyId={this.state.selectedCompany.id}
          liftUpUpdateCounterPartDetails={this.liftUpUpdateCounterPartDetails}
        />
        {this.state.isPastContactCreateUpdatePopUpOpen && this.state.employees && this.state.employees.length > 0 && (
          <PastContactCreateUpdatePopUp
            open={this.state.isPastContactCreateUpdatePopUpOpen}
            isPastContactDefaultPhoneCall={this.state.isPastContactDefaultPhoneCall}
            handleClose={this.handleClosePastContactCreateUpdatePopUp}
            handleSnackbarOpen={this.props.handleSnackbarOpen}
            companyId={this.state.selectedCompany.id}
            counterParts={this.state.counterParts}
            liftUpUpdatePastContactDetails={this.liftUpUpdatePastContactDetails}
            employees={this.state.employees}
          />
        )}
        <AddReminderPopUp
          open={this.state.isAddReminderPopUpOpen}
          handleClose={this.handleCloseAddReminderPopup}
          handleSnackbarOpen={this.props.handleSnackbarOpen}
          companyId={this.state.selectedCompany.id}
          counterParts={this.state.counterParts}
          getCounterPartReminderListByCompanyAPICall={this.getCounterPartReminderListByCompanyAPICall}
        />
        {this.state.showCompanyDetails && (
          <Fab color="primary" aria-label="Collapse" style={collapseIconStyle} onClick={this.handleCollapseIconButtonClick}>
            {this.state.showCompanyDetailsColumn ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </Fab>
        )}
        <Fab
          buttonRef={node => {
            this.anchorEl = node;
          }}
          color="primary"
          aria-label="Add"
          style={{ position: 'absolute', bottom: '30px', right: '30px', zIndex: 1000 }}
          onClick={this.handleAddMenuIconButtonClick}
        >
          <AddIcon />
        </Fab>
        <Popover
          open={this.state.isPopoverOpen}
          anchorEl={this.anchorEl}
          anchorReference="anchorEl"
          onClose={() => this.setState({ isPopoverOpen: false })}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
        >
          <div className="p-2 d-flex flex-column">
            <MenuItem
              key={TEXT.companyArchive.addButton.createCompanyEntry}
              onClick={() =>
                this.setState({
                  isCompanyCreateUpdatePopUpOpen: true,
                  isPopoverOpen: false
                })
              }
              color="inherit"
            >
              <i className="material-icons pr-2">library_add</i>
              {TEXT.companyArchive.addButton.createCompanyEntry}
            </MenuItem>
            {isPermissionSet(this.props.user, 'dataImportCompanies') && (
              <MenuItem
                key={TEXT.companyArchive.addButton.excelImport}
                onClick={() =>
                  this.setState({
                    isFilePickerModalOpen: true,
                    isPopoverOpen: false
                  })
                }
                color="inherit"
              >
                <i className="material-icons pr-2">import_export</i>
                {TEXT.companyArchive.addButton.excelImport}
              </MenuItem>
            )}
            {CONSTANTS.isLienert && (
              <>
                <MenuItem key={TEXT.companyArchive.addButton.excelExport} onClick={() => this.handleExport()} color="inherit">
                  <i className="material-icons pr-2">import_export</i>
                  {TEXT.companyArchive.addButton.excelExport}
                </MenuItem>

                <MenuItem
                  key={TEXT.companyArchive.addButton.excelChristmasExport}
                  onClick={() => this.handleExport('christmas')}
                  color="inherit"
                >
                  <i className="material-icons pr-2">import_export</i>
                  {TEXT.companyArchive.addButton.excelChristmasExport}
                </MenuItem>
              </>
            )}
            <Divider />
            <MenuItem
              key={TEXT.companyArchive.addButton.createContactEntry}
              disabled={!this.state.showCompanyDetails}
              onClick={() =>
                this.setState({
                  isCounterPartCUPopUpOpen: true,
                  isPopoverOpen: false
                })
              }
            >
              <i className="material-icons pr-2">person_add</i>
              {TEXT.companyArchive.addButton.createContactEntry}
            </MenuItem>
            <MenuItem
              key={TEXT.companyArchive.addButton.createCommentEntry}
              disabled={!this.state.showCompanyDetails}
              onClick={() =>
                this.setState({
                  isPastContactCreateUpdatePopUpOpen: true,
                  isPopoverOpen: false,
                  isPastContactDefaultPhoneCall: false
                })
              }
            >
              <i className="material-icons pr-2">add_comment</i>
              {TEXT.companyArchive.addButton.createCommentEntry}
            </MenuItem>
            <MenuItem
              key={TEXT.companyArchive.addButton.createReminder}
              disabled={!this.state.showCompanyDetails}
              onClick={() =>
                this.setState({
                  isAddReminderPopUpOpen: true,
                  isPopoverOpen: false
                })
              }
            >
              <i className="material-icons pr-2">notifications</i>
              {TEXT.companyArchive.addButton.createReminder}
            </MenuItem>
            <Divider />
            <MenuItem
              key={TEXT.companyArchive.addButton.createProject}
              disabled={!this.state.showCompanyDetails}
              onClick={() =>
                this.setState({
                  showCreateProjectModular: true,
                  createProjectButtonIsDisabled: false,
                  isPopoverOpen: false
                })
              }
            >
              <i className="material-icons pr-2">group</i>
              {TEXT.companyArchive.addButton.createProject}
            </MenuItem>
            {CONSTANTS.isLienert && (
              <>
                <Divider />
                <MenuItem
                  key={TEXT.companyArchive.addButton.createOffer}
                  onClick={() =>
                    this.setState({
                      selectedOffer: {},
                      showCreateOfferModular: true,
                      createProjectButtonIsDisabled: false,
                      isPopoverOpen: false
                    })
                  }
                  color="inherit"
                >
                  <i className="material-icons pr-2">post_add</i>
                  {TEXT.companyArchive.addButton.createOffer}
                </MenuItem>
              </>
            )}
          </div>
        </Popover>
        <ConfirmNewProjectPopUp
          key="confirmation-create-Project"
          handleClose={() => this.setState({ showCreateProjectModular: false })}
          open={this.state.showCreateProjectModular}
          buttonPrimaryAction={() => this.handleCreateProjectClick(this.state.selectedCompany.id, this.state.projectNumber)}
          headlineText={TEXT.companyArchive.createProjectModal.headline}
          // descriptionText={this.renderDescriptionCreateProject()}
          // contentText={this.renderContentCreateProject()}
          selectedCompany={this.state.selectedCompany}
          buttonPrimaryText={TEXT.companyArchive.createProjectModal.buttonPrimaryText}
          onProjectNumberChange={this.onProjectNumberChange}
          primaryButtonIsDisabled={this.state.createProjectButtonIsDisabled}
        />
        <OfferCreateUpdatePopUp
          handleClose={() => this.showOffersCreateUpdatePopUp(false)}
          open={this.state.showCreateOfferModular}
          employees={this.state.employees}
          handleSnackbarOpen={this.props.handleSnackbarOpen}
          companyDetails={this.state.selectedCompany}
          errorHandling={this.errorHandling}
          handleChangeTablePageNumber={this.handleChangeOfferTablePageNumber}
          offerData={this.state.selectedOffer}
          isUpdate={Object.keys(this.state.selectedOffer).length > 0}
        />
        {this.state.partners.length > 0 && this.state.employees.length > 0 && (
          <CompanyDetailSearch
            liftUpStateToApp={this.props.liftUpStateToApp}
            isDrawerOpen={this.state.isDetailSearchDrawerOpen}
            handleDrawerClose={() =>
              this.setState({
                isDetailSearchDrawerOpen: false
              })
            }
            employeesList={this.state.employees}
            partnerList={this.state.partners.map(partner => {
              if (partner.status === 'deleted') {
                return {
                  ...partner,
                  partnerName: `${partner.partnerName} (ausgeschieden)`
                };
              }
              return partner;
            })}
            updateCompanyArchive={(detailSearchResult, maxAmountOfSearchResults) =>
              this.setState({
                isDetailSearchDrawerOpen: false,
                showResultList: true,
                companyList: detailSearchResult,
                maxAmountOfSearchResults: maxAmountOfSearchResults || 100
              })
            }
            handleSnackbarOpen={this.props.handleSnackbarOpen}
            user={this.props.user}
          />
        )}
      </div>
    );
  }
}
CompanyArchive.defaultProps = {};
CompanyArchive.propTypes = {
  handleSnackbarOpen: PropTypes.func.isRequired,
  liftUpStateToApp: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};
export default withRouter(withUser(CompanyArchive));
